import React from "react";
import { Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import CookbookSection from "../../components/cookbookSection";

const SideDishesCookbookSection = ({ location }) => {
  return (
    <CookbookSection
      location={location}
      sectionLink="side-dishes"
      sectionName="Side Dishes"
      sectionTitle="Side Dish"
      prevLink="/cookbook/sandwiches/"
      nextLink="/cookbook/snacks/"
    >
      <Row className="d-flex pb-4 pt-4 flex-wrap justify-content-center" id="cookbook-top-text">
        <div className="ps-4 pe-4 pb-4 cookbook-top-section d-flex flex-column">
          <div>Make it a great meal with a side dish</div>
          <div className="pt-2">
            <StaticImage width={300} src="../../images/cookbook/frying-pan.jpg" alt="Frying pan" />
          </div>
        </div>
        <div className="ps-4 pe-4 pb-4 cookbook-top-section d-flex flex-column">
          <div className="flex-fill">... or go straight to dessert</div>
          <div className="pt-2">
            <StaticImage width={300} src="../../images/cookbook/tent-on-sandbar.jpg" alt="Tent on sandbar" />
          </div>
        </div>
      </Row>
    </CookbookSection>
  );
};

export default SideDishesCookbookSection;
